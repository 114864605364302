<style>
@media (min-width: 1025px) {
  .profile-mobile {
    display: none !important;
  }
}

.account-link {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: .5px;
  color: #bfc8e2 !important;
}

.account-link:hover {
  color: #a7b0c9 !important;
}

.account-links {
  margin-left: 5rem;
  margin-top: 2rem;
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none;
  user-select: none;
}

.account-component-col {
  margin-top: 2rem;
}

.account-link-active {
  color: white !important;
}

.mobile-menu {
  width: 100vw;
  height: 3rem;
  background-color: #545a6d;
  display: flex;
  overflow-x: scroll;
  margin-bottom: 1rem;
  margin-left: -25px;
}

.mobile-container {
  height: 3rem;
  min-width: 4rem;
  text-align: center;
  padding-top: 0.75rem;
  padding-bottom: 2rem;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  color: white;
  white-space: nowrap;
}

.mobile-container.active {
  border-bottom: 3px solid var(--primary);
}
</style>

<script>
import {VclCode} from 'vue-content-loading';
import Multiselect from "vue-multiselect";

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AccountConnections: () => import("@/components/lazy/account/desktop/AccountConnections"),
    AccountSecurity: () => import("@/components/lazy/account/desktop/AccountSecurity"),
    AccountGeneral: () => import("@/components/lazy/account/desktop/AccountGeneral"),
    AccountSettings: () => import("@/components/lazy/account/desktop/AccountSettings"),
    VclCode,
    Multiselect
  },
  data() {
    return {
      avatar_url: process.env.VUE_APP_ROOT_API + 'v1/@me/avatar',
      ready: false,
      error: false,
      account: {},
      currentTab: 'AccountGeneral',
      is_mobile: null,
      options: {}
    };
  },
  created() {
    this.getData()
  },
  mounted() {
    try {
      // Should this fail for whatever reason, fall back
      let element = document.getElementsByClassName('profile-mobile')[0];
      this.is_mobile = window.getComputedStyle(element, null).display === 'block';
    } catch (e) {
      console.error(e);
      this.is_mobile = null;
    }
    this.getData();
  },
  methods: {
    handleError: function (error) {
      console.error(`[ERROR] ${error}`);
      this.error = true;
    },
    async getData() {
      try {
          const response = await fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/account', {credentials: 'include'});
          if (!response.ok){
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
          const data = await response.json();
          this.account = data.account;
          this.ready = true;
      } catch(error){
        this.error = true;
        this.handleError(error);
      }
    },
    changeTab(tab, options) {
      this.options = Object.assign({}, this.options, options);
      this.currentTab = tab;
    },
    tabChangeRequest(tab) {
      this.currentTab = tab;
    },
  }
};
</script>
<template>
  <div>
    <!-- Desktop -->
    <div class="profile-desktop">
      <div class="container-fluid" v-if="is_mobile === false && ready">
        <div class="row">
          <div class="col-2">
            <div class="account-links">
              <div class="account-link text-muted" :class="{'account-link-active': currentTab === 'AccountGeneral'}"
                   v-on:click="changeTab('AccountGeneral')">
                {{ $t("account.general") }}
              </div>
              <div class="account-link text-muted" :class="{'account-link-active': currentTab === 'AccountSecurity'}"
                   v-on:click="changeTab('AccountSecurity')">
                {{ $t("account.security.title") }}
              </div>
              <div class="account-link text-muted" :class="{'account-link-active': currentTab === 'AccountConnections'}"
                   v-on:click="changeTab('AccountConnections')">
                {{ $t("account.connections.title") }}
              </div>
              <div class="account-link text-muted" :class="{'account-link-active': currentTab === 'AccountSettings'}"
                   v-on:click="changeTab('AccountSettings')">
                {{ $t("account.settings") }}
              </div>
            </div>
          </div>
          <div class="col account-component-col">
            <component @tabChangeRequest="tabChangeRequest" :account="account" :options="options"
                       :cftools_id="account.cftools_id" v-bind:is="currentTab" :isMobile="is_mobile"></component>
          </div>
        </div>
      </div>
    </div>
    <!-- Mobile -->
    <div class="profile-mobile">
      <div v-if="is_mobile && ready">
        <div class="mobile-menu">
          <div class="mobile-container" :class="{'active': currentTab === 'AccountGeneral'}"
               v-on:click="changeTab('AccountGeneral')">
            {{ $t("account.general") }}
          </div>
          <div class="mobile-container" :class="{'active': currentTab === 'AccountSecurity'}"
               v-on:click="changeTab('AccountSecurity')">
            {{ $t("account.security.title") }}
          </div>
          <div class="mobile-container" :class="{'active': currentTab === 'AccountConnections'}"
               v-on:click="changeTab('AccountConnections')">
            {{ $t("account.connections.title") }}
          </div>
          <div class="mobile-container" :class="{'active': currentTab === 'AccountSettings'}"
               v-on:click="changeTab('AccountSettings')">
            {{ $t("account.settings") }}
          </div>
        </div>
        <div class="col account-component-col">
          <component @tabChangeRequest="tabChangeRequest" :account="account" :options="options"
                     :cftools_id="account.cftools_id" v-bind:is="currentTab"></component>
        </div>
      </div>
    </div>
    <!-- SERVER ERROR -->
    <div v-if="error">
      <div class="row">
        <div class="col-lg">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{ $t('account.error.overview.title') }}
              </h5>
            </div>
            <div class="card-body">
              <h5 class="card-title mt-0"> {{ $t('account.error.overview.description') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- LOADING ANIMATION -->
    <div class="row" v-else-if="!error && !ready">
      <div class="col-lg-3">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="media">
                  <div class="media-body align-self-center">
                    <vcl-code :height="90"></vcl-code>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>